import { useEffect } from 'react';

import { colsubsidio_settings } from '~implementations/colsubsidio';
import { coosalud_settings } from '~implementations/coosalud';
import { fci_settings } from '~implementations/fci';
import { horizonte_settings } from '~implementations/horizonte';
import { incans_settings } from '~implementations/incans';
import { rimac_settings } from '~implementations/rimac';
import { salud_total_settings } from '~implementations/saludTotal';

const allAppSettings = {
  colsubsidio: colsubsidio_settings,
  coosalud: coosalud_settings,
  coosaludTest: coosalud_settings,
  fci: fci_settings,
  rimac: rimac_settings,
  rimacTest: rimac_settings,
  horizonte: horizonte_settings,
  incans: incans_settings,
  salud_total: salud_total_settings
};

type Name = keyof typeof allAppSettings;
export type Logos = keyof ImplementationSettings['logos'];
export type Envs = keyof ImplementationSettings['envs'];

export interface AppImplementation {
  name: Name;
  envs: Record<Envs, string>;
  logos: Record<Logos, string>;
  workUnits: WorkUnit[];
}

const implementation = import.meta.env.VITE_IMPLEMENTATION as Name;
const appSettingsSelected = allAppSettings[implementation];

const useImplementation = () => {
  useEffect(() => {
    const implementationColors = Object.entries(appSettingsSelected.colors);
    implementationColors.map((color) => {
      document.documentElement.style.setProperty(color[0], color[1]);
    });
  }, []);

  return {
    name: implementation,
    ...appSettingsSelected
  };
};

export default useImplementation;
