export default [
  {
    pk: 1,
    page_name: 'Presunción Mama',
    indicators: [
      {
        name: 'Presunción Mama',
        uuid: '91544488-870c-4bc5-abb1-b4747288e7df'
      }
    ]
  },
  {
    pk: 2,
    page_name: 'Presunción Prostata',
    indicators: [
      {
        name: 'Presunción Prostata',
        uuid: 'c9bba6d9-9548-4a0b-bebe-d5fdc78b6580'
      }
    ]
  },
  {
    pk: 3,
    page_name: 'Primer trimestre',
    indicators: [
      {
        name: 'Primer trimestre',
        uuid: '52f682c8-afef-42d3-858f-6328ae9cb711'
      }
    ]
  },
  {
    pk: 4,
    page_name: 'Segundo trimestre',
    indicators: [{
      name: 'Primer trimestre',
      uuid: '52f682c8-afef-42d3-858f-6328ae9cb711'
    }]
  },
  {
    pk: 5,
    page_name: 'Tercer trimestre',
    indicators: []
  },
  {
    pk: 6,
    page_name: 'Evento obstétrico',
    indicators: []
  },
  {
    pk: 7,
    page_name: 'Control Postnatal',
    indicators: []
  },
  {
    pk: 8,
    page_name: 'Ingreso de pacientes',
    indicators: []
  },
  {
    pk: 9,
    page_name: 'Onco mama',
    indicators: []
  },
  {
    pk: 10,
    page_name: 'Onco prostata',
    indicators: []
  },
  {
    pk: 11,
    page_name: 'Neonatos',
    indicators: []
  },
  {
    pk: 15,
    page_name: 'Infante menor a 1 año',
    indicators: []
  },
  {
    pk: 16,
    page_name: 'Neonatal',
    indicators: []
  }
] as WorkUnit[];
